<template>
  <div id="max-score-info" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-8 is-offset-2">
          <p class="title is-4 has-text-right">
            {{ classGroupName }}
          </p>
        </div>
        <div
          class="column is-8 is-offset-2 card mb-3"
          v-for="item in scores"
          :key="item.id"
        >
          <p class="title is-5">{{ item.name }}</p>
          <p class="subtitle is-6">Max Score: {{ item.score }}</p>
        </div>
        <div class="column is-8 is-offset-2 pr-0">
          <action-buttons
            @initialize-update="initializeUpdate()"
            @initialize-remove="initializeRemove()"
            font-size="1.2"
            :show-info="false"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'graduates',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Max Score Info',
      classGroupName: null,
      maxScoresByClassGroup: [],
      maxScoreId: null,
      scores: [],
      isPaginated: true,
    }
  },
  watch: {
    maxScoresByClassGroup(data) {
      if (data.length < 20) this.isPaginated = false
      const {
        id,
        faName,
        faMaxMark,
        saName,
        saMaxMark,
        ftName,
        ftMaxMark,
        stName,
        stMaxMark,
        examsName,
        examsMaxMark,
        classGroup: { name: classGroupName },
      } = data[0]

      this.maxScoreId = id

      this.classGroupName = classGroupName

      this.scores = [
        { name: faName, score: faMaxMark },
        { name: saName, score: saMaxMark },
        { name: ftName, score: ftMaxMark },
        { name: stName, score: stMaxMark },
        { name: examsName, score: examsMaxMark },
      ]
    },
  },
  methods: {
    initializeUpdate(max_score) {
      this.$router.push(`/school/${this.schoolId}/max_score/${this.maxScoreId}`)
    },
    initializeRemove(max_score) {
      this.$buefy.notification.open({
        duration: 5000,
        message: 'Not Allowed.',
        position: 'is-top',
        type: 'is-danger',
        hasIcon: true,
      })
      return
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    const classGroupId = parseInt(this.$route.params.id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Class Group Info',
        route: `/school/${this.schoolId}/class_group_info/${classGroupId}`,
      },
      {
        name: 'Class Groups',
        route: `/school/${this.schoolId}/class_groups`,
      },
    ])
    this.$apollo.addSmartQuery('maxScoresByClassGroup', {
      query: gql`
        query MaxScoreInfoQuery($classGroupId: ID!) {
          maxScoresByClassGroup(classGroupId: $classGroupId) {
            id
            classGroup {
              id
              name
            }
            faName
            faMaxMark
            saName
            saMaxMark
            ftName
            ftMaxMark
            stName
            stMaxMark
            examsName
            examsMaxMark
          }
        }
      `,
      variables: {
        classGroupId: classGroupId,
      },
    })
    this.$apollo.queries.maxScoresByClassGroup.refetch()
  },
}
</script>
